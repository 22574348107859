/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconBusinessElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-business';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect x="9" y="9" width="2" height="2"/>
    <rect x="13" y="9" width="2" height="2"/>
    <rect x="9" y="6" width="2" height="2"/>
    <rect x="13" y="6" width="2" height="2"/>
    <rect x="9" y="12" width="2" height="2"/>
    <rect x="13" y="12" width="2" height="2"/>
    <path d="M20,19.25H18.75V4A.75.75,0,0,0,18,3.25H6A.75.75,0,0,0,5.25,4V19.25H4a.75.75,0,0,0,0,1.5H20a.75.75,0,0,0,0-1.5Zm-10.25,0v-2.5h4.5v2.5Zm6,0v-4H8.25v4H6.75V4.75h10.5v14.5Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconBusinessElement.is,
    window.JhaIconBusinessElement);
export default window.JhaIconBusinessElement;
